.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: rgba($light-grey, 0.2);
  }

  &__copy {
    font-weight: 400;
    color: rgba($light-grey, 0.6);
  }
}