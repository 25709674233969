.header {
  position: relative;
  height: 80px;
  display: none;

  @media (min-width: 1024px) {
    display: block;
    height: 300px;
  }

  &__img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    inset: 0;
  }

  &--mobile {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: $dark-grey;

    @media (min-width: 1024px) {
      display: none;
    }

    .header__img {
      position: relative;
      width: 70px;
      height: auto;
    }
  }
}