@import "playlists";
@import "artists";

.main {
  flex: 1;
  padding: 30px 0;

  &__content {
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1200px;
  }
}