$m-width: 280px;
$d-width: 320px;
$m-height: 360px;
$d-height: 320px;

.main {
  &__playlists {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;

    @media (min-width: 568px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1180px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__playlist {
    width: 100%;
    margin-bottom: 20px;
    text-decoration: none;
    overflow: hidden;
    border-radius: 15px;
    background-color: $white;
    color: $dark-grey;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

    &:hover {
      box-shadow: none;
    }

    @media (min-width: 1024px) {
      max-width: $d-width;
      margin-bottom: 0;
    }

    &-content {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    &-title {
      align-self: center;
      font-size: 24px;
    }

    &-artists {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      li {
        position: relative;
        padding-right: 20px;
        font-size: 14px;
        line-height: 24px;

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 11px;
          right: 9px;
          height: 4px;
          width: 4px;
          background-color: $dark-grey;
          border-radius: 50%;
        }

        &:last-of-type {
          padding-right: 0;
          &:after {
            display: none;
          }
        }
      }
    }

    &-img {
      position: relative;
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.main-v1 {
  &__playlists {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &__playlist {
    width: 100%;
    max-width: 450px;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
      max-width: 320px;
      margin-bottom: 0;
    }
  }
}