.main {
  &__artists {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    margin-top: 30px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1180px) {
      grid-template-columns: repeat(3, 1fr);
    }

    &--first {
      margin-bottom: 40px;
    }

    .main__artist {
      display: flex;
      align-items: center;
      text-decoration: none;

      &:hover {
        .main__artist-img{
          box-shadow: none;
        }

        .main__artist-name{
          border-color: transparent;
        }
      }

      &-name {
        text-decoration: none;
        font-size: 24px;
        margin-left: 20px;
        color: $dark-grey;
        border-bottom: 2px dashed $dark-grey;
      }

      &-img {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}