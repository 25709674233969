*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

html {
  height: 100%;
  min-height: 100%;
  font-size: 14px;
}

body {
  min-width: 320px;
  height: 100%;
  min-height: 100%;
  word-break: break-word;
  -webkit-word-break: break-word;
  background: #fff;
  margin: 0;
  overflow-x: hidden;
}

.global-wrapper {
  min-height: 100vh;
  width: 100%;
  background: #f2f2f2;
  flex-direction: column;
  display: flex;
}

.header {
  height: 80px;
  display: none;
  position: relative;
}

@media (min-width: 1024px) {
  .header {
    height: 300px;
    display: block;
  }
}

.header__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.header--mobile {
  background-color: #252525;
  align-content: center;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  .header--mobile {
    display: none;
  }
}

.header--mobile .header__img {
  width: 70px;
  height: auto;
  position: relative;
}

.main__playlists {
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}

@media (min-width: 568px) {
  .main__playlists {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1180px) {
  .main__playlists {
    grid-template-columns: repeat(3, 1fr);
  }
}

.main__playlist {
  width: 100%;
  color: #252525;
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  display: flex;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
}

.main__playlist:hover {
  box-shadow: none;
}

@media (min-width: 1024px) {
  .main__playlist {
    max-width: 320px;
    margin-bottom: 0;
  }
}

.main__playlist-content {
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.main__playlist-title {
  align-self: center;
  font-size: 24px;
}

.main__playlist-artists {
  flex-wrap: wrap;
  margin: 15px 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.main__playlist-artists li {
  padding-right: 20px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
}

.main__playlist-artists li:after {
  content: "";
  height: 4px;
  width: 4px;
  background-color: #252525;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 11px;
  right: 9px;
}

.main__playlist-artists li:last-of-type {
  padding-right: 0;
}

.main__playlist-artists li:last-of-type:after {
  display: none;
}

.main__playlist-img {
  display: block;
  position: relative;
}

.main__playlist-img img {
  width: 100%;
  height: auto;
}

.main-v1__playlists {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .main-v1__playlists {
    flex-direction: row;
  }
}

.main-v1__playlist {
  width: 100%;
  max-width: 450px;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .main-v1__playlist {
    max-width: 320px;
    margin-bottom: 0;
  }
}

.main__artists {
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 30px;
  display: grid;
}

@media (min-width: 768px) {
  .main__artists {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1180px) {
  .main__artists {
    grid-template-columns: repeat(3, 1fr);
  }
}

.main__artists--first {
  margin-bottom: 40px;
}

.main__artists .main__artist {
  align-items: center;
  text-decoration: none;
  display: flex;
}

.main__artists .main__artist:hover .main__artist-img {
  box-shadow: none;
}

.main__artists .main__artist:hover .main__artist-name {
  border-color: rgba(0, 0, 0, 0);
}

.main__artists .main__artist-name {
  color: #252525;
  border-bottom: 2px dashed #252525;
  margin-left: 20px;
  font-size: 24px;
  text-decoration: none;
}

.main__artists .main__artist-img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
}

.main__artists .main__artist img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.main {
  flex: 1;
  padding: 30px 0;
}

.main__content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer {
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.footer:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(150, 150, 150, .2);
  position: absolute;
  top: 0;
}

.footer__copy {
  color: rgba(150, 150, 150, .6);
  font-weight: 400;
}

/*# sourceMappingURL=index-with-artists.369bc8f1.css.map */
