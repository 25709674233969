html {
  font-size: 14px;
  height: 100%;
  min-height: 100%;
}


body {
  margin: 0;
  background: #fff;
  min-width: 320px;
  height:100%;
  min-height:100%;
  word-break: break-word;
  -webkit-word-break: break-word;
  overflow-x: hidden;
}